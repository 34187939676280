export const icons = {
    home: <i className="fa-solid fa-house"></i>,
    addressCard: <i className="fa-solid fa-address-card"></i>,
    homeInactive: <i className="fa-solid fa-house-line"></i>,
    transactions: <i className="fa-solid fa-credit-card"></i>,
    accounts: <i className="fa-solid fa-wallet"></i>,
    settings: <i className="fa-solid fa-cog"></i>,
    logout: <i className="fa-solid fa-sign-out"></i>,
    trend: <i className="fa-solid fa-money-bill-trend-up"></i>,
    expenses: <i className="fa-solid fa-money-bill-transfer"></i>,
    money: <i className="fa-solid fa-receipt"></i>,
    freelance: <i className="fa-solid fa-earth-americas"></i>,
    stocks: <i className="fa-solid fa-arrow-trend-up"></i>,
    bitcoin: <i className="fa-brands fa-bitcoin"></i>,
    piggy: <i className="fa-solid fa-piggy-bank"></i>,
    yt: <i className="fa-brands fa-youtube"></i>,
    card: <i className="fa-brands fa-cc-visa"></i>,
    users: <i className="fa-solid fa-users-between-lines"></i>,
    dollar: <i className="fa-solid fa-dollar-sign"></i>,
    calender: <i className="fa-solid fa-calendar"></i>,
    comment: <i className="fa-solid fa-comment"></i>,
    plus: <i className="fa-solid fa-plus"></i>,
    trash: <i className="fa-solid fa-trash"></i>,
    signOut: <i className="fa-solid fa-right-from-bracket"></i>,
    takeaway: <i className="fa-solid fa-utensils"></i>,
    clothing: <i className="fa-solid fa-tshirt"></i>,
    book: <i className="fa-solid fa-book-open"></i>,
    food: <i className="fa-solid fa-food"></i>,
    medical: <i className="fa-solid fa-briefcase-medical"></i>,
    tv: <i className="fa-solid fa-tv"></i>,
    circle: <i className="fa-solid fa-circle-dot"></i>,
    incomes: <i className="fa-solid fa-money-stack"></i>,
    budget: <i className="fa-solid fa-money-check"></i>,
    reports: <i className="fa-solid fa-chart-bar"></i>,
    login: <i className="fa-solid fa-sign-in"></i>,
    rupees: <i className="fa-solid fa-indian-rupee-sign"></i>,
    linkedIn: <i className="fa-brands fa-linkedin"></i>,
    medium: <i className="fa-brands fa-medium"></i>,
    stackOverflow: <i className="fa-brands fa-stack-overflow"></i>,
    github: <i className="fa-brands fa-github"></i>,
    twitter: <i className="fa-brands fa-twitter"></i>,
    instagram: <i className="fa-brands fa-instagram"></i>,
    facebook: <i className="fa-brands fa-facebook"></i>,
    youtube: <i className="fa-brands fa-youtube"></i>,
    mail: <i className="fa-solid fa-envelope"></i>,
    phone: <i className="fa-solid fa-phone"></i>,
    whatsApp: <i className="fa-brands fa-whatsapp"></i>,
    location: <i className="fa-solid fa-location"></i>,
    edit: <i className="fa-solid fa-edit"></i>,
    about: <i className="fa-solid fa-user"></i>,
    journey: <i className="fa-solid fa-route"></i>,
    projects: <i className="fa-solid fa-project-diagram"></i>,
    contact: <i className="fa-solid fa-address-book"></i>,
    resume: <i className="fa-solid fa-file"></i>,
    social: <i className="fa-solid fa-users"></i>,
    blog: <i className="fa-solid fa-blog"></i>,
    menu: <i className="fa-solid fa-menu"></i>,
    hamburger: <i className="fa-solid fa-bars"></i>,
    cross: <i className="fa-solid fa-times"></i>,
    JavaScript: <i className="fa-brands fa-js-square"></i>,
    React: <i className="fa-brands fa-react"></i>,
    Redux: <i className="fa-brands fa-react"></i>,
    HTML: <i className="fa-brands fa-html5"></i>,
    CSS: <i className="fa-brands fa-css3-alt"></i>,
    Sass: <i className="fa-brands fa-sass"></i>,
    Bootstrap: <i className="fa-brands fa-bootstrap"></i>,
    MaterialUI: <i className="fa-brands fa-material-ui"></i>,
    StyledComponents: <i className="fa-brands fa-styled-components"></i>,
    Python: <i className="fa-brands fa-python"></i>,
    database: <i className="fa-solid fa-database"></i>,
    TSQL: <i className="fa-brands fa-microsoft"></i>,
    MySQL: <i className="fa-brands fa-mysql"></i>,
    SQL: <i className="fa-brands fa-microsoft"></i>,
    PostgreSQL: <i className="fa-brands fa-postgresql"></i>,
    MongoDB: <i className="fa-brands fa-mongodb"></i>,
    Git: <i className="fa-brands fa-git"></i>,
    "C++": <i className="fa-brands fa-cuttlefish"></i>,
    TypeScript: (
        <>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 128 128"
                id="typescript"
                width="1em"
                height="1em"
                style={{ marginBottom: "-3px" }}
            >
                <path
                    fill="#007acc"
                    d="M2,63.91v62.5H127V1.41H2Zm100.73-5a15.56,15.56,0,0,1,7.82,4.5,20.58,20.58,0,0,1,3,4c0,.16-5.4,3.81-8.69,5.85-.12.08-.6-.44-1.13-1.23a7.09,7.09,0,0,0-5.87-3.53c-3.79-.26-6.23,1.73-6.21,5a4.58,4.58,0,0,0,.54,2.34c.83,1.73,2.38,2.76,7.24,4.86,8.95,3.85,12.78,6.39,15.16,10,2.66,4,3.25,10.46,1.45,15.24-2,5.2-6.9,8.73-13.83,9.9a38.32,38.32,0,0,1-9.52-.1A23,23,0,0,1,80,109.19c-1.15-1.27-3.39-4.58-3.25-4.82a9.34,9.34,0,0,1,1.15-.73L82.5,101l3.59-2.08.75,1.11a16.78,16.78,0,0,0,4.74,4.54c4,2.1,9.46,1.81,12.16-.62a5.43,5.43,0,0,0,.69-6.92c-1-1.39-3-2.56-8.59-5-6.45-2.78-9.23-4.5-11.77-7.24a16.48,16.48,0,0,1-3.43-6.25,25,25,0,0,1-.22-8c1.33-6.23,6-10.58,12.82-11.87A31.66,31.66,0,0,1,102.73,58.93ZM73.39,64.15l0,5.12H57.16V115.5H45.65V69.26H29.38v-5a49.19,49.19,0,0,1,.14-5.16c.06-.08,10-.12,22-.1L73.33,59Z"
                ></path>
            </svg>
        </>
    ),
};
