import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { icons } from "../../utils/icons.js";
// import { useWindowSize } from "../../utils/useWindowSize.js";

const Footer = () => {
    return (
        <FooterStyled>
            <Title>Contact:</Title>
            <FooterContent>
                <div></div>
                <ContactDetails>
                    <p>
                        <a
                            href="tel:+919513868175"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <span>{icons.phone} Call</span>
                        </a>
                    </p>
                    <p>
                        <a
                            // href="https://api.whatsapp.com/send?phone=919513868175&text=Hello%20Master%20Satyam!"
                            // href="https://typecard.com/vcard/29037f07/"
                            href="https://typecard.com/29037f07/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <span>{icons.addressCard} Save Contact</span>
                        </a>
                    </p>

                    <p>
                        <a
                            href="mailto:contact@satyamanand.in"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <span>{icons.mail} Send Mail</span>
                        </a>
                    </p>
                    <p>
                        <a
                            href="https://cal.com/satyam-anand-axzwo2/contact"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <span>{icons.calender} Book Calendar</span>
                        </a>
                    </p>
                </ContactDetails>
                <SocialMedia>
                    <SocialLink
                        href="https://www.linkedin.com/in/satyamanand/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <span>{icons.linkedIn} LinkedIn</span>
                    </SocialLink>
                    <SocialLink
                        href="https://github.com/SatyamAnand98"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <span>{icons.github} Github </span>
                    </SocialLink>
                    <SocialLink
                        href="https://stackoverflow.com/users/8416928/satyam-anand"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <span>{icons.stackOverflow} stackOverflow </span>
                    </SocialLink>
                    <SocialLink
                        href="https://satyam-anand.medium.com"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <span>{icons.medium} Medium </span>
                    </SocialLink>
                </SocialMedia>
            </FooterContent>
        </FooterStyled>
    );
};

const FooterStyled = styled.footer`
    position: flex;
    flex-position: bottom;
    margin-bottom: 0.5rem !important;
    background-color: inherit;
    border-radius: 50%;
    animation: burst 1.5s ease-in-out;

    @keyframes burst {
        0% {
            transform: scale(0);
        }
        50% {
            transform: scale(1.2);
        }
        100% {
            transform: scale(1);
        }
    }

    @keyframes bounce {
        0%,
        40%,
        80% {
            transform: translateY(0);
        }
        10%,
        30%,
        50%,
        70%,
        90% {
            transform: translateY(-100px);
        }
        20%,
        60%,
        100% {
            transform: translateY(0);
        }
    }

    padding: 0.5vw;
    display: flex;
    flex-direction: column;
    border: 3px solid #282429;
    border-radius: 32px;
    margin-top: 0.5rem;

    &::-webkit-scrollbar {
        width: 0;
    }

    @media (max-width: 768px) {
        border-radius: 16px;
    }

    @media (max-width: 480px) {
        border-radius: 8px;
    }
`;

const Title = styled.h1`
    margin: 0.5rem;
    font-size: 1.5rem;
    color: var(--color-light);
`;

const FooterContent = styled.div`
    display: grid;
    grid-template-columns: 0.8fr 500px 1fr;
    @media (max-width: 768px) {
        grid-template-columns: 0.3fr 200px 1fr;
    }

    @media (max-width: 480px) {
        grid-template-columns: 0px 1fr 1fr;
    }
    gap: 1rem;
    margin: 1rem;
`;

const SocialMedia = styled.div`
    p {
        margin-bottom: 0.5rem;
    }
`;

const SocialLink = styled.a`
    display: flex;
    background-color: var(--color-footer);
    align-items: center;
    // width: fit-content;
    justify-content: center;
    text-decoration: none;
    color: var(--color-light);
    border-radius: 5px;
    border: 1px solid var(--color-light);
    border-radius: 5px;
    margin-top: 5px;
    padding: 5px;
    margin-bottom: 20px;
    transition: all 0.3s ease-in-out;
    &:hover {
        transform: scale(1.1);
        background-color: var(--color-grey);
        color: var(--color-grey);
    }
    @media (min-width: 768px) {
        width: 200px;
    }

    @media (min-width: 480px) {
        width: 200px;
    }
`;

const ContactDetails = styled.div`
    p {
        display: flex;
        background-color: var(--color-footer);
        align-items: center;
        // width: fit-content;
        justify-content: center;
        text-decoration: none;
        color: var(--color-light);
        border-radius: 5px;
        border: 1px solid var(--color-light);
        border-radius: 5px;
        margin-top: 5px;
        padding: 5px;
        margin-bottom: 20px;
        transition: all 0.3s ease-in-out;
        &:hover {
            transform: scale(1.1);
            background-color: var(--color-grey);
            color: var(--color-grey);
        }
        @media (min-width: 768px) {
            width: 200px;
        }
        @media (min-width: 480px) {
            width: 200px;
        }
    }

    a {
        text-decoration: none;
        color: inherit;
    }
`;

export default Footer;
